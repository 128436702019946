import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { MetaFunctionArgs } from '@engined/core/interfaces.js';
import { Box, Paper, styled } from '@mui/material';
import { AUTH_LOGIN_WELCOME, SEO_APP_LABEL } from '@asaprint/asap/locales/client.js';
import Copyright from '@engined/client/components/Copyright.js';
import React from 'react';
import { Outlet } from 'react-router-dom';

const Root = styled('main')(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: 500,
  marginLeft: 'auto',
  marginRight: 'auto',
}));

Root.displayName = 'Root';

interface OwnProps {
  className?: string;
}

type Props = OwnProps;

const AuthLayout: React.FunctionComponent<Props> = ({ className }) => {
  const { t } = useLocale();

  return (
    <Root className={className}>
      <Box component="h1" fontSize="10rem" color="#e6e6e6" fontWeight="800" letterSpacing="-10px" m={0}>
        ASAP
      </Box>

      <Paper sx={{ p: 8, textAlign: 'center', width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}></Box>

        <h3>{t(AUTH_LOGIN_WELCOME)}</h3>

        <Outlet />
      </Paper>

      <Copyright sx={{ mt: 4 }} />
    </Root>
  );
};

AuthLayout.displayName = 'AuthLayout';

export default AuthLayout;

export const handle = {
  meta: ({ locale: { t } }: MetaFunctionArgs) => ({
    title: `${t(SEO_APP_LABEL)}`,
  }),
};
